<template>
  <div class="pa-9">
    <v-row>
      <v-col cols="6">
        <div class="my-5"><h3>Khóa học</h3></div>
        <v-autocomplete
            outlined
            dense
            :name="'course_id'"
            :items="courses"
            :item-text="'title'"
            :item-value="'id'"
            v-model="course_id"
            :placeholder="'Chọn khóa học'"
        />
      </v-col>
      <v-col cols="6">
        <div class="my-5"><h3>Học viên</h3></div>
        <v-autocomplete
            outlined
            dense
            :name="'student_id'"
            :items="students"
            :item-text="'email'"
            :item-value="'id'"
            v-model="student_ids"
            multiple
            :placeholder="'Chọn học viên '"
        />
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="3">
        <v-btn color="primary" type="submit" form="check-footer" @click="saveSetting">Cấu hình</v-btn>
      </v-col>
    </v-row>
    <v-container>
      <template>
        <v-data-table :headers="headers" :items="orders" class="elevation-1">
          <template v-slot:item.action="{ item }">
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </template>
    </v-container>
  </div>
</template>
<script>
import {
  GET_LIST_COURSE_MANAGE_SETTING,
  ORDER_COURSE_MANAGER,
  DELETE_ORDER_SETTING
} from "@/store/orders.module";
import {
  GET_LIST_COURSE,
} from "@/store/courses.module";
import {GET_STUDENTS} from "@/store/user.module";
import {mapGetters} from "vuex";


export default {
  data: () => {
    return {
      headers: [
        {
          text: "Tên khóa học",
          align: "start",
          sortable: false,
          value: "title",
        },
        {text: "Người mua", value: "full_name"},
        {text: "Email", value: "email"},
        {text: "Số điện thoại", value: "phone_number"},
        {text: "Ngày mua", value: "active_date"},
        {text: "Ngày hết hạn", value: "expiration_date"},
        {text: "Hành động", value: "action"},
      ],
      course_id: null,
      student_ids: []
    }
  },
  async created() {
    await this.$store.dispatch(GET_LIST_COURSE_MANAGE_SETTING, {});
    await this.$store.dispatch(GET_STUDENTS);
    await this.$store.dispatch(GET_LIST_COURSE);
  },
  computed: {
    ...mapGetters({
      orders: "orderList",
      courses: "listCourse",
      students: "listStudents"
    })
  },
  methods: {
    async saveSetting() {
      if (!this.course_id) {
        this.$toasted.error('Bạn chưa chọn khóa học !!', {
          position: "top-right",
          theme: "toasted-primary",
          duration: 3000
        })
        return false
      }
      if (this.student_ids.length == 0) {
        this.$toasted.error('Bạn chưa chọn học viên !!', {
          position: "top-right",
          theme: "toasted-primary",
          duration: 3000
        })
        return false
      }
      let payload = {
        course_id: this.course_id,
        student_ids: this.student_ids,

      }
      await this.$store.dispatch(ORDER_COURSE_MANAGER, payload).then(data => {
        if (data.data.status) {
          this.$toasted.success('Cấu hình thành công !!', {
            position: "top-right",
            theme: "toasted-primary",
            duration: 3000
          })
          this.$store.dispatch(GET_LIST_COURSE_MANAGE_SETTING, {});
          this.course_id = null
          this.student_ids = []
        }
      })
    },
    deleteItem(item) {
      confirm("Bạn có chắc chăm muốn xóa ??") &&
      this.$store
          .dispatch(DELETE_ORDER_SETTING, {
            id: item.id
          })
          .then(() => {
            this.$toasted.success('Xóa cấu hình thành công !!', {
              position: "top-right",
              theme: "toasted-primary",
              duration: 3000
            })
            this.$store.dispatch(GET_LIST_COURSE_MANAGE_SETTING, {});
          });
    }
  }
}
</script>